import type { RouteRecordRaw } from "vue-router";

const routes: Readonly<RouteRecordRaw[]> = [
    {
        path: "/",
        name: "home",
        component: () => import("../views/Home.vue"),
    },
];

export default routes;

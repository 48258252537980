import { initializeApp } from "firebase/app";

const firebaseConfig = {
    apiKey: "AIzaSyCcxBu0jn9Qr7k5UQiJaqgOSSQGAGWuYoc",
    authDomain: "wizarr-v3.firebaseapp.com",
    projectId: "wizarr-v3",
    storageBucket: "wizarr-v3.appspot.com",
    messagingSenderId: "163741477369",
    appId: "1:163741477369:web:5aee8a3c42835f94d2b528",
};

export default initializeApp(firebaseConfig);

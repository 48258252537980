import titleCase from "./titleCase";
import underscroreSpaces from "./underscoreSpaces";
import firstLetterUppercase from "./firstLetterUppercase";
import removeVersion from "./removeVersion";
import timeAgo from "./timeAgo";
import toMinutes from "./toMinutes";
import isPast from "./isPast";
import dateLess from "./dateLess";

const filters = {
    titleCase,
    underscroreSpaces,
    firstLetterUppercase,
    removeVersion,
    timeAgo,
    toMinutes,
    isPast,
    dateLess,
};

export default filters;
